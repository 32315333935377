import { XMarkIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import BaseImageView from "./BaseImageView";
import BaseAudioView from "./BaseAudioView";
import React, { useState } from "react";
import "react18-json-view/src/style.css";
import JsonView from "react18-json-view";

const FinalUI = () => {
  const [json, setJson] = useState();
  const [url, setUrl] = useState("");

  const [sensorInformation, setSensorInformation] = useState();
  const [gpioInformation, setGpioInformation] = useState();
  const [otherInformation, setOtherInformation] = useState();
  const [nvmeInformation, setNvmeInformation] = useState();
  const [cameraInformation, setCameraInformation] = useState();
  const [cameraOSInformation, setCameraOSInformation] = useState();
  const [microphoneInformation, setMicrophoneInformation] = useState();

  const loadURL = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(url);
      const json = await response.json();

      console.log(json);

      setSensorInformation(json.test_results.i2c_sensors);
      setGpioInformation(json.test_results.gpio_peripherals);
      setOtherInformation({
        osStatus: json.test_results.os_status,
        nerdctlStatus: json.test_results.nerdctl_status,
      });
      setNvmeInformation(json.test_results.nvme_status);
      setCameraInformation(json.test_results.cameras);
      setCameraOSInformation(json.test_results.ip_cam_os_status);
      setMicrophoneInformation(json.test_results.microphones);
      setJson(json);
    } catch (err) {
      console.error(err);
    }
  };

  const clear = () => {
    setUrl("");
    setSensorInformation(null);
    setGpioInformation(null);
    setOtherInformation(null);
    setNvmeInformation(null);
    setCameraInformation(null);
    setMicrophoneInformation(null);
    setJson(null);
  };

  const getTestStatus = (testStatus) => {
    if (testStatus) {
      return (
        <div className="flex gap-2 items-center">
          <span>Passed</span>
          <CheckCircleIcon className="text-green-400 h-5 w-5" />
        </div>
      );
    } else {
      return (
        <div className="flex gap-2 items-center">
          <span>Failed</span>
          <XMarkIcon className="text-red-500 h-5 w-5 stroke-2" />
        </div>
      );
    }
  };

  const getTestDetails = (testDetails, testStatus) => {
    if (testStatus) {
      return <>-</>;
    } else {
      return <>{testDetails}</>;
    }
  };

  return (
    <div className="flex flex-col gap-4 flex-1 p-4">
      <h1 className="text-2xl font-semibold text-slate-500">
        <div>Horcery</div>
        <div className="text-base">Station 4.1 Validation</div>
      </h1>

      <form onSubmit={loadURL}>
        <div className="flex gap-2 h-fit">
          {/* <input type="file" onChange={handleFile} /> */}
          <input
            className="border px-4 py-2 rounded-lg w-full"
            type={"text"}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter URL here"
            disabled={!!json}
          />
          {!json ? (
            <button className="text-sm px-4 py-1.5 border-none w-56 bg-blue-500 text-white rounded-md hover:bg-blue-600 duration-300">
              Load URL
            </button>
          ) : (
            <button
              onClick={clear}
              className="text-sm px-4 py-1.5 border-none w-56 bg-blue-500 text-white rounded-md hover:bg-blue-600 duration-300"
            >
              Clear
            </button>
          )}
        </div>
      </form>

      <div className="grid grid-cols-1 gap-4">
        {otherInformation && (
          <div className="bg-white border p-4 rounded-xl">
            <h2 className="text-xl font-semibold text-slate-500 mb-4">
              Device Information
            </h2>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col border gap-2 p-4 bg-slate-100 rounded-lg">
                <div className="text-sm font-bold text-slate-500">
                  OS Status
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">Version:</span>{" "}
                    {otherInformation.osStatus.version}
                  </div>
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      CPU Usage:
                    </span>{" "}
                    {otherInformation.osStatus.cpu_usage}%
                  </div>
                  {/* code_name */}
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      Code Name:
                    </span>{" "}
                    {otherInformation.osStatus.code_name}
                  </div>
                  {/* distributor */}
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      Distributor:
                    </span>{" "}
                    {otherInformation.osStatus.distributor}
                  </div>
                  <div className="flex flex-1 p-3 items-start border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      Test Status:
                    </span>{" "}
                    {nvmeInformation?.status}
                    {getTestStatus(nvmeInformation?.status)}
                  </div>
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      Test Details:
                    </span>{" "}
                    {getTestDetails(
                      nvmeInformation?.details,
                      nvmeInformation?.status
                    )}
                  </div>
                </div>
                <div>
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      Horcery OS Version:
                    </span>{" "}
                    {getTestDetails(
                      otherInformation.osStatus.horcery_os_version
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-col border gap-2 p-4 bg-slate-100 rounded-lg">
                <div className="text-sm font-bold text-slate-500">
                  Nerdctl Status
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">Version:</span>{" "}
                    {otherInformation.nerdctlStatus.version}
                  </div>
                  <div className="flex flex-1 p-3 items-start border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      Test Status:
                    </span>{" "}
                    {otherInformation.nerdctlStatus?.status}
                    {getTestStatus(otherInformation.nerdctlStatus?.status)}
                  </div>
                  <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                    <span className="text-slate-500 font-normal">
                      Test Details:
                    </span>{" "}
                    {getTestDetails(
                      otherInformation.nerdctlStatus?.details,
                      otherInformation.nerdctlStatus?.status
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* nvme */}
        {nvmeInformation && (
          <div className="bg-white border p-4 rounded-xl">
            <h2 className="text-xl font-semibold text-slate-500 mb-4">NVME</h2>

            <div className="flex flex-col border gap-2 p-4 bg-slate-100 rounded-lg">
              <div className="text-sm font-bold text-slate-500">
                Device Information
              </div>
              <div className="grid grid-cols-5 gap-2">
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">Name:</span>{" "}
                  {nvmeInformation.name}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Model Number:
                  </span>{" "}
                  {nvmeInformation.model_number}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Serial Number:
                  </span>{" "}
                  {nvmeInformation.serial_number}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Firmware Version:
                  </span>{" "}
                  {nvmeInformation.firmware_version}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Critical Warning:
                  </span>{" "}
                  {nvmeInformation.critical_warning}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Power Cycles:
                  </span>{" "}
                  {nvmeInformation.power_cycles}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Power On Hours:
                  </span>{" "}
                  {nvmeInformation.power_on_hours}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Unsafe Shutdowns:
                  </span>{" "}
                  {nvmeInformation.unsafe_shutdowns}
                </div>
                <div className="flex flex-1 p-3 items-start border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Test Status:
                  </span>{" "}
                  {nvmeInformation.status}
                  {getTestStatus(nvmeInformation.status)}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Test Details:
                  </span>{" "}
                  {getTestDetails(
                    nvmeInformation.details,
                    nvmeInformation.status
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {sensorInformation && (
        <div className="bg-white border p-4 rounded-xl">
          <h2 className="text-xl font-semibold text-slate-500 mb-4">
            Sensor Infomation
          </h2>

          <div className=" rounded-lg overflow-hidden border">
            <table className="w-full font-medium text-sm">
              <thead className="bg-slate-100 border-b text-slate-500">
                <tr>
                  <th className="py-3 px-4 text-left">Sensor Name</th>
                  <th className="py-3 px-4 text-left">Sensor ID</th>
                  <th className="py-3 px-4 text-left">Address</th>
                  <th className="py-3 px-4 text-left">Data Unit</th>
                  <th className="py-3 px-4 text-left">Test Status</th>
                  <th className="py-3 px-4 text-left">Test Details</th>
                  <th className="py-3 px-4 text-left">JSON</th>
                </tr>
              </thead>
              <tbody className="divide-y text-sm">
                {sensorInformation.length !== 0 ? (
                  sensorInformation.map((sensor) => (
                    <tr key={sensor.id}>
                      <td className="py-3 px-4">{sensor.sensor_name}</td>
                      <td className="py-3 px-4">{sensor.id}</td>
                      <td className="py-3 px-4">{sensor.address}</td>
                      <td className="py-3 px-4">{sensor.data_unit}</td>
                      <td className="py-3 px-4">
                        {getTestStatus(sensor.status)}
                      </td>
                      <td className="py-3 px-4">
                        <div className="flex gap-2 items-center">
                          {getTestDetails(sensor.details, sensor.status)}
                        </div>
                      </td>
                      <td className="py-3 px-4">
                        <div className="p-3 max-w-xs overflow-scroll bg-gray-100 rounded-lg">
                          <JsonView src={{ data: sensor.data }} collapsed />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="py-3 px-4 text-center">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {gpioInformation && (
        <div className="bg-white border p-4 rounded-xl">
          <h2 className="text-xl font-semibold text-slate-500 mb-4">
            GPIO Peripherals
          </h2>

          <div className=" rounded-lg overflow-hidden border">
            <table className="w-full font-medium text-sm">
              <thead className="bg-slate-100 border-b text-slate-500">
                <tr>
                  <th className="py-3 px-4 text-left">Name</th>
                  <th className="py-3 px-4 text-left">ID</th>
                  <th className="py-3 px-4 text-left">Test Status</th>
                  <th className="py-3 px-4 text-left">Test Details</th>
                  <th className="py-3 px-4 text-left">Data Points</th>
                  <th className="py-3 px-4 text-left">JSON</th>
                </tr>
              </thead>
              <tbody className="divide-y text-sm">
                {gpioInformation.length !== 0 ? (
                  gpioInformation.map((gpio) => (
                    <tr key={gpio.id}>
                      <td className="py-3 px-4">{gpio.name}</td>
                      <td className="py-3 px-4">{gpio.id}</td>
                      <td className="py-3 px-4">
                        {getTestStatus(gpio.status)}
                      </td>
                      <td className="py-3 px-4">
                        <div className="flex gap-2 items-center">
                          {getTestDetails(gpio.details, gpio.status)}
                        </div>
                      </td>
                      <td className="py-3 px-4">
                        <div className="p-3 max-w-xs overflow-scroll bg-gray-100 rounded-lg">
                          <div className="flex gap-2">
                            {gpio.data.map((dataPoint, index) => {
                              const value = dataPoint ? Math.round(+dataPoint * 100) / 100 : 0;

                              return (
                                <span key={index}>
                                  {value},
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </td>
                      <td className="py-3 px-4">
                        <div className="p-3 max-w-xs overflow-scroll bg-gray-100 rounded-lg">
                          <JsonView src={{ gpio }} collapsed />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="py-3 px-4 text-center">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {cameraInformation && (
        <div className="bg-white border p-4 gap-4 flex flex-col rounded-xl">
          <h2 className="text-xl font-semibold text-slate-500">
            Camera Information
          </h2>

          <div className="grid gap-4">
            <div className="flex flex-col border gap-2 p-4 bg-slate-100 rounded-lg">
              <div className="text-sm font-bold text-slate-500">
                Camera OS Status
              </div>
              <div className="grid grid-cols-3 gap-2">
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">Version:</span>
                  {cameraOSInformation.version}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    VENC Version:
                  </span>
                  {cameraOSInformation.venc_version}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Controller Version:
                  </span>
                  {cameraOSInformation.controller_version}
                </div>
                <div className="flex flex-1 p-3 items-start border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Test Status:
                  </span>
                  {getTestStatus(cameraOSInformation.status)}
                </div>
                <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                  <span className="text-slate-500 font-normal">
                    Test Details:
                  </span>
                  {cameraOSInformation.details}
                </div>
              </div>
            </div>
          </div>

          {cameraInformation.map((camera) => {
            console.log(camera);
            return (
              <div
                key={camera.id}
                className="flex flex-col gap-4 items-center border p-4 bg-slate-100 rounded-lg"
              >
                <div className="flex flex-1 flex-col gap-2 w-full">
                  <div className="text-sm font-bold text-slate-500">
                    {camera.name}
                  </div>
                  <div className="flex gap-2">
                    <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                      <span className="text-slate-500 font-normal">
                        Test Status:
                      </span>{" "}
                      {getTestStatus(camera.status)}
                    </div>
                    <div className="flex flex-1 p-3 border bg-white rounded-lg text-sm font-semibold gap-2">
                      <span className="text-slate-500 font-normal">
                        Output Type:
                      </span>{" "}
                      {camera.output_type}
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 w-full flex-1">
                  <div className="rounded-lg overflow-hidden w-full">
                    <BaseImageView base64String={camera.image} />
                  </div>
                  <div className="rounded-lg overflow-hidden w-full">
                    <BaseImageView base64String={camera.image_ir} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {microphoneInformation && (
        <div className="bg-white border p-4 rounded-xl flex flex-col gap-4">
          <h2 className="text-xl font-semibold text-slate-500">
            Microphone Information
          </h2>

          <div className=" rounded-lg overflow-hidden border">
            <table className="w-full font-medium text-sm">
              <thead className="bg-slate-100 border-b text-slate-500">
                <tr>
                  <th className="py-3 px-4 text-left">Name</th>
                  <th className="py-3 px-4 text-left">ID</th>
                  <th className="py-3 px-4 text-left">Test Status</th>
                  <th className="py-3 px-4 text-left">Test Details</th>
                  <th className="py-3 px-4 text-left">JSON</th>
                </tr>
              </thead>
              <tbody className="divide-y text-sm">
                {microphoneInformation.length !== 0 ? (
                  microphoneInformation.map((mic) => (
                    <tr key={mic.id}>
                      <td className="py-3 px-4">{mic.name}</td>
                      <td className="py-3 px-4">{mic.id}</td>
                      <td className="py-3 px-4">{getTestStatus(mic.status)}</td>
                      <td className="py-3 px-4">
                        <div className="flex gap-2 items-center">
                          {getTestDetails(mic.details, mic.details)}
                        </div>
                      </td>
                      <td className="py-3 px-4">
                        <div className="p-3 max-w-xs overflow-scroll bg-gray-100 rounded-lg">
                          <JsonView src={{ mic }} collapsed />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="py-3 px-4 text-center">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex gap-4 w-full">
            <BaseAudioView base64Audio={microphoneInformation[0].audio} />
          </div>
        </div>
      )}

      <h2 className="text-xl font-semibold text-slate-500">
        Raw JSON Information
      </h2>
      <div className="bg-slate-200 border-2 p-4 text-sm rounded-lg">
        {json && <JsonView src={json} />}
      </div>
    </div>
  );
};

export default FinalUI;
